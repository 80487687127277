<template>
  <div class="card mt-5" :class="$style.container">
    <div class="text-dark font-size-28 mb-3">Реквизиты компании</div>
    <a-form
      :model="accountForm"
      :rules="rules"
      layout="vertical"
      class="mb-4"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item name="tin" label="ИНН/ПИНФЛ">
        <a-input
          type="number"
          v-model:value="accountForm.tin"
          placeholder="Введите ИНН/ПИНФЛ"
        />
      </a-form-item>
<!--        <a-select-->
<!--          @change="updateCertificate"-->
<!--          v-model:value="accountForm.certificate"-->
<!--          placeholder="Выберите сертификат"-->
<!--          label-in-value-->
<!--        >-->
<!--          <a-select-option :disabled="cert.validTo < new Date()" v-for="cert in certificates" :key="cert.name" :value="cert.name">-->
<!--            {{ getCertificateLabel(cert) }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-item>-->
      <a-form-item name="mfo" label="МФО">
        <a-input
          type="number"
          v-model:value="accountForm.mfo"
          placeholder="Введите МФО"
        />
      </a-form-item>
      <a-form-item name="account_no" label="Расчетный счет">
        <a-input
          type="number"
          v-model:value="accountForm.account_no"
          placeholder="Введите расчетный счет"
        />
      </a-form-item>
      <a-form-item>
        <a-button style="width: 48%;" type="default" @click="back">Назад</a-button>
        <a-button style="width: 48%; float:right" type="primary" html-type="submit" @click="() => {}">Далее</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import {onMounted, reactive, ref, watch} from "vue";
import EIMZO from "@/helpers/e-imzo"

export default {
  name: 'AccountView',
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['back', 'success'],
  setup(props, ctx) {
    let eimzo
    const certificate = ref()
    const certificates = ref([])
    onMounted(async () => {
      if (props.form.tin || props.form.mfo || props.form.account_no) {
        accountForm.tin = props.form.tin
        accountForm.mfo = props.form.mfo
        accountForm.account_no = props.form.account_no
      }
      try {
        eimzo = new EIMZO
        await eimzo.install()
        certificates.value = await eimzo.listAllUserKeys()
      } catch (e) {
        console.log(e)
      }
    })
    const rules = {
      // value: [
      //   {
      //     required: true,
      //     message: 'Please choose certificate!',
      //     trigger: 'change',
      //   },
      // ],
      tin: [
        {
          required: true,
          message: 'Please input tin!',
          trigger: 'blur',
        },
        {
          message: 'Length should be 9 to 14!',
          trigger: 'blur',
          min: 9,
          max: 14
        },
      ],
      mfo: [
        {
          required: true,
          message: 'Please input mfo!',
          trigger: 'blur',
        },
        {
          message: 'Length should be 5!',
          trigger: 'blur',
          min: 5,
          max: 5
        }
      ],
      account_no: [
        {
          required: true,
          message: 'Please input account!',
          trigger: 'blur',
        },
        {
          message: 'Length should be 20!',
          trigger: 'blur',
          min: 20,
          max: 20
        }
      ],
    }
    const accountForm = reactive({
      certificate: null,
      value: null,
      tin: null,
      fio: null,
      juridical: null,
      position: null,
      mfo: null,
      account_no: null,
    })
    const updateCertificate = item => {
      const selectedCertificate = certificates.value.find(cert => cert.name === item.value)
      accountForm.value = item.value
      accountForm.fio = selectedCertificate['CN']
      accountForm.position = selectedCertificate['T']
      accountForm.company = selectedCertificate['O']
      accountForm.tin = selectedCertificate['TIN']
        ? selectedCertificate['TIN']
        : selectedCertificate['PINFL']
      // eimzo.signPkcs7(selectedCertificate, '').then((response) => {
      //   console.log(response)
      //   accountForm.fio = selectedCertificate['CN']
      //   accountForm.tin = selectedCertificate['TIN'].length
      //     ? selectedCertificate['TIN']
      //     : selectedCertificate['PINFL']
      //
      // }).catch((err) => {
      //   console.log(err)
      // }).finally(() => {
      //   console.log('finally')
      // })
    }
    const getCertificateLabel = (cert) => {
      if (cert['O']) {
        return `${cert['O']} (${cert['TIN']})`
      }
      return `${cert['CN']} (${cert['PINFL']}`
    }
    const back = () => {
      ctx.emit('back')
    }
    const handleFinish = () => {
      ctx.emit('success', accountForm)
      // if (accountForm.tin) {
      //   ctx.emit('success', accountForm)
      // } else {
      //   notification.warning({
      //     message: 'Пожалуйста выберите сертификат'
      //   })
      //   return;
      // }
    }
    const handleFinishFailed = () => {
      // console.log(errors)
    }
    watch(props.form, form => {
      accountForm.tin = form.tin
      accountForm.mfo = form.mfo
      accountForm.account_no = form.account_no
    })
    return {
      rules,
      accountForm,
      certificate,
      certificates,
      back,
      handleFinish,
      updateCertificate,
      handleFinishFailed,
      getCertificateLabel,
    }
  }
}
</script>
<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>

